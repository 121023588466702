/* eslint-disable */
import React from "react"
import styled from '@emotion/styled'
import Section from './blocks/section'
import Column from './blocks/column'
import Text from '../atoms/text'
import { intlShape, injectIntl, FormattedMessage} from 'react-intl';

import Facebook from "../images/facebook.svg";
import Instagram from "../images/instagram.svg";

const LegalText = styled.small`
    border-top: 2px solid #AEAEAE;
    padding: 30px 0;
    margin-top: 25px;
    span {
        font-family: 'Montserrat' !important;
        font-size: 9pt;
        line-height: 14pt;
        color: #AEAEAE;
        a{
            font-family: 'Montserrat',sans-serif;
            color: #AEAEAE;
        }

    }
    @media(max-width: 961px){
        padding: 25px 2%;
        margin: 0 2% 25px;
    }

`

const Social = styled.img`
    width: 20px;
    display: inline-block;
    margin: 10px;
`

const FooterLink = styled.a`
    font-family:Montserrat !important;
    color:#373737;
    text-decoration:none;
    font-size:11pt;
    display: block;
    margin: 10px 15px 0 0;
`

const SocialContainer = styled.div`
    @media(max-width: 961px){
        display: flex;
        flex-direction: row;
        margin-top: 25px;
        margin-left: 2%;
    }
`

const ResponsiveContaier = styled.div`
    display: flex;
    flex-direction: row;
    @media(max-width: 961px){
        display: block;
        margin-left: 4%;
        margin-bottom: 45px;
    }
`

const ResponsiveContaier2 = styled.div`
    display: block;
    width: 100%;
    text-align: right;
    a, p {
        text-align: right;
    }
    @media(max-width: 961px){
        margin-left: 4%;
        width: 96%;
        margin-bottom: 45px;
        text-align: left;
        a, p {
            text-align: left;
        }
    }
`

const ContestFooter = ({locale,landing,extraLines}) => (
  <footer>
      <Section>
          <Column
              width="65%"
              direction="row">
              <SocialContainer>
                  <a href="https://www.facebook.com/RoyaleKittensChatons" target="_blank"><Social src={Facebook} /></a>
                  <a href="https://www.instagram.com/theroyalekittens/" target="_blank"><Social src={Instagram} /></a>
              </SocialContainer>

              <Column
                  width="100%"
                  direction="row"
                  margin="20px 0 0 0"
                  >
                  <ResponsiveContaier>
                      {landing ? (<FooterLink href={locale == 'fr' ?  '/springcleaningoffers':"/fr/offresdenettoyageduprintemps"}>{locale == 'fr' ? 'English':'Français'}</FooterLink>) : (<FooterLink href={locale == 'fr' ?  '../refreshyourspace':"../fr/rafraichirsondecor"}>{locale == 'fr' ? 'English':'Français'}</FooterLink>) }
                      <FooterLink href={locale == 'fr' ? '../gear_up_for_spring_official_rules_fr.pdf':"/gear_up_for_spring_official_rules_en.pdf"} target="_blank">{locale == 'fr' ?  'Reglèments officiels':'Official Rules'}</FooterLink>
                      <FooterLink href={locale == 'fr' ? "https://www.royale.ca/fr/confidentialite":"https://www.royale.ca/privacy-policy"} target="_blank">{locale == 'fr' ? 'Confidentialité':'Privacy Policy'}</FooterLink>
                      <FooterLink href={locale == 'fr' ? "https://www.royale.ca/fr/avis-juridique":"https://www.royale.ca/terms-of-service"} target="_blank">{locale == 'fr' ? 'Conditions d\'utilisation':'User agreement'}</FooterLink>
                  </ResponsiveContaier>

              </Column>
          </Column>
          <Column
              width="35%">
              <ResponsiveContaier2>
                  <Text
                      upperCase
                      fontFamily="Montserrat-Bold"
                      margin="0"><FormattedMessage id='contest.questions' /></Text>
                  <a
                      style={{
                          'fontFamily':'Montserrat',
                          'color':'#ACACAC',
                          'textDecoration':'none',
                          'fontSize':'16pt'
                      }}
                      href="mailto:support@royale.ca?subject=Royale.ca%20Website%20Inquiry">
                      support@royale.ca
                  </a>
              </ResponsiveContaier2>
          </Column>
          <Column
              width="70%">
              <LegalText>
                  <FormattedMessage id='landing.footer4'>
                    {(txt) => (
                      <span style={{fontWeight: '900'}} dangerouslySetInnerHTML={{__html: txt}}/>
                    )}
                  </FormattedMessage>
                    <br /><br />
                  <FormattedMessage id='contest.footerlegal'>
                    {(txt) => (
                      <span dangerouslySetInnerHTML={{__html: txt}}/>
                    )}
                  </FormattedMessage>
                  <br /><br />
                  <FormattedMessage id='contest.footerlegal2'>
                    {(txt) => (
                      <span dangerouslySetInnerHTML={{__html: txt}}/>
                    )}
                  </FormattedMessage>
                  { extraLines ?
                      <>
                        <br /><br />
                          <FormattedMessage id='landing.footer1'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html: txt}}/>
                            )}
                          </FormattedMessage>
                          <br /><br />
                          <FormattedMessage id='landing.footer2'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html: txt}}/>
                            )}
                          </FormattedMessage>
                          <br /><br />
                          <FormattedMessage id='landing.footer3'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html: txt}}/>
                            )}
                          </FormattedMessage>
                      </>
                    :
                    null
                  }

              </LegalText>
          </Column>
      </Section>
  </footer>
)

export default ContestFooter
