/* eslint-disable */
import React from "react"
import styled from '@emotion/styled'
import PropTypes from "prop-types"

const SectionContainer = styled.div`
    width: ${props => props.fullWidth ? '100%' : props.width ? props.width : '80%'};
    height: ${props => props.height ? props.height : 'auto'};
    margin: ${props => props.fullWidth ? '0' : props.width ? '0':'0 10%'};
    padding: ${props => props.padding ? props.padding : '50px 0'};
    display: ${props => props.display ? props.display : 'flex'};
    flex-direction: ${props => props.direction ? props.direction : 'row'};
    flex-wrap: ${props => props.wrap ? props.wrap : 'wrap'};
    align-items:${props => props.centerMode ? 'center' : 'flex-start'};
    justify-content:${props => props.centerMode ? 'center' : 'flex-start'};
    background-color: ${props => props.backgroundColor ? props.backgroundColor : 'transparent'};
    @media(max-width: 961px){
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin: 0;
        height: auto;
    }
`

const Section = ({
    children,
    fullWidth,
    width,
    height,
    display,
    direction,
    wrap,
    padding,
    centerMode,
    backgroundColor
  }) => (
      <SectionContainer
          fullWidth={fullWidth}
          padding={padding}
          display={display}
          direction={direction}
          wrap={wrap}
          centerMode={centerMode}
          width={width}
          height={height}
          backgroundColor={backgroundColor}
          >
          {children}
      </SectionContainer>
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth:PropTypes.bool,
  padding:PropTypes.string,
  display:PropTypes.string,
  direction:PropTypes.string,
  wrap:PropTypes.string,
  centerMode:PropTypes.bool
}

export default Section
