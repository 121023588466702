/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import {intlShape, injectIntl, FormattedMessage} from 'react-intl';
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Imgix from "react-imgix"
import ProductLocatorBlock from '../../blocks/ProductLocatorBlock'
import ContestFooter from '../../../contest/components/ContestFooter'


import Text from '../../common/Text/'
import NewLogo from '../../../assets/images/newlogo@2x.png'
import NewLogoFR from '../../../assets/images/GUFS_LOGO_SHORT_FR.png'
import Background from '../../../assets/images/landing_back.jpg'
import BannerGreen from '../../../assets/images/banner_green@2x.png'
import LogoRoyale from '../../../assets/images/logo.png'

import Product1 from '../../../assets/images/product1_2.png'
import Product2 from '../../../assets/images/product2_2.png'
import Product3 from '../../../assets/images/product3_2.png'
import Product4 from '../../../assets/images/product4_2.png'
import Product5 from '../../../assets/images/product5_2.png'
import Product6 from '../../../assets/images/product6_2.png'

const Flex = css`
  display: flex;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  @media(max-width: 1300px){
    width: 95%;
  }
  @media(max-width: 769px){
    width: 100%;
    display: block;
  }
`
const LandingWrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  padding: 9% 0 0;
  background-image: url(${Background});
  background-size: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  > img {
    position: relative;
    width: 100%;
  }
`
const Landing = styled.div`
  width: 100%;
`

const FlexContainer = styled.div`
  ${Flex}
`

const Container = styled.div(props => ({
  width: props.width,
  padding: props.padding,
  margin: props.margin,
  position: 'relative'
}))
const SizedContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  @media(max-width: 991px){
    width: 100%;
    margin: 0;
    padding: 4% 2% 7% 0;
  }

  img {
    width: 100%;
  }
`
const Card = styled.div`
  position: relative;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  min-height: ${props => props.locale == 'en' ? '320px' : '435px'};
  padding: 15px 10px 15px;
  box-shadow: 0 0 36px 0 rgba(0,0,0,0.44);
  border-radius: 5px;
  flex-grow:1;
  @media(max-width: 991px){
    height: auto;
    margin: 0 0 0 1%;
  }
  @media(max-width: 691px){
    display: block;
    height: auto;
    margin: 0 0 0 1%;
    > div:nth-child(2), div:nth-child(3) {
      padding: 0;
      margin: 0;
    }
  }
`
const RoundNumber = styled.div`
  background-color: #006838;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  padding: 6px 0 0 0;
  text-align: center;
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  border-radius: 100%;
  font-family: 'Montserrat',sans-serif;
  z-index: 10;
`

const LogoImage = styled.img`
  position: absolute;
  top: 10px;
  left: 50px;
  width: 160px;
  z-index: 998;
  @media(max-width: 769px){
    top: 12px;
    left: 20px;
    width: 100px;
  }
`
const ResponsiveLogo = styled(Imgix)`
  width: 20%;
  margin: 50px 40% 2%;
  @media(max-width: 769px){
    width: 30%;
    margin: 3% 35% 0;
  }
`


const ResponsiveText = styled(Text)`
  font-weight: 300;
  margin: 3% auto 10%;
  @media(max-width: 769px){
    margin: 3% 5% 0%;
    font-weight: 500;
  }
`

const SmallBanner = styled.div`
    width: 94%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px 0 0;
    background-image: url(${BannerGreen});
    background-size: 100% 35px;
    background-repeat: no-repeat;
    background-position: center top;
    height: 50px;
    p {
        margin-top: 3px;
        font-family: 'Montserrat',sans-serif;
        font-weight: bold;
        letter-spacing: 8px;
    }
    @media(max-width: 900px){
        p {
            padding-top: 3px;
        }
    }

`

const LandingPage = (props) => {

  return (
      <>
          <LandingWrapper locale={props.locale}>
            <Link to={props.locale === 'en' ? '/' : '/fr/'}>
              <LogoImage src={LogoRoyale} alt="Royale" />
            </Link>
            <Landing>
              <ResponsiveLogo src={props.locale == 'en' ? NewLogo:NewLogoFR} alt="Gear Up 4 Spring" width="15%" />
              <FlexContainer>
                <SizedContainer
                  width="80%"
                  margin="0 20%">
                  <ResponsiveText
                    center={true}
                    textColor="#ffffff"
                    textSize="16pt"
                    lineHeight="22pt">
                    <FormattedMessage id='landing.main1' />
                  </ResponsiveText>
                </SizedContainer>
                <SmallBanner>
                    <ResponsiveText
                      center={true}
                      textColor="#ffffff"
                      textSize="16pt"
                      lineHeight="22pt"
                      margin="0"
                      padddng="0"
                      textType="header">
                      <FormattedMessage id='landing.limited' />
                    </ResponsiveText>
                </SmallBanner>
              </FlexContainer>
              <FlexContainer>
                <SizedContainer
                  width="48%"
                  margin="1%">
                  <Card locale={props.locale}>
                    <RoundNumber>1</RoundNumber>
                      <SizedContainer
                        width="50%"
                        padding="4% 2% 2%"
                        margin="0 0 0">
                        <Imgix src={Product1} width={400} loading="lazy" alt="Product" />
                      </SizedContainer>
                      <SizedContainer
                        width="50%">
                        <Text
                          textSize="12pt"
                          margin="6% 0 0"
                          padding="0"
                          lineHeight="16pt"
                          upperCase={true}
                          textColor="#9AC623">
                          <FormattedMessage id='landing.1_1'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html:txt}} />
                            )}
                          </FormattedMessage>
                        </Text>

                        <Text
                          textType="header"
                          mobileTextSize={props.locale == 'en' ? '27pt' : '24pt'}
                          textSize={props.locale == 'en' ? '29pt' : '21pt'}
                          margin="1% 0 0"
                          padding="0"
                          lineHeight={props.locale == 'en' ? '30pt' : '24pt'}
                          upperCase={true}
                          bolder={true}
                          textColor="#006838">
                          <FormattedMessage id='landing.1_2'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html:txt}} />
                            )}
                          </FormattedMessage>

                        </Text>
                        <Text

                          textSize="12pt"
                          margin="5px 0 0"
                          padding="0"
                          lineHeight="16pt"
                          upperCase={true}
                          textColor="#006838">
                          <FormattedMessage id='landing.or_fantastik'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html:txt}} />
                            )}
                          </FormattedMessage>
                        </Text>
                        <Text
                          margin="4% 0 0"
                          textColor="#51586E"
                          textSize="11pt"
                          lineHeight="18pt">
                          <FormattedMessage id='landing.1_3'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html:txt}} />
                            )}
                          </FormattedMessage>

                        </Text>
                      </SizedContainer>
                  </Card>
                </SizedContainer>
                <SizedContainer
                  width="48%"
                  margin="1%">
                  <Card locale={props.locale}>
                    <RoundNumber>2</RoundNumber>
                    <SizedContainer
                      width="50%"
                      padding="4% 2% 2%"
                      margin="0 0 0">
                      <Imgix src={Product2} width={400} loading="lazy" alt="Product" />
                    </SizedContainer>
                    <SizedContainer
                      width="50%">
                      <Text

                        textSize="12pt"
                        margin="6% 0 0"
                        padding="0"
                        lineHeight="16pt"
                        upperCase={true}
                        textColor="#9AC623">
                        <FormattedMessage id='landing.2_1'>
                          {(txt) => (
                            <span dangerouslySetInnerHTML={{__html:txt}} />
                          )}
                        </FormattedMessage>
                      </Text>
                      <Text
                        textType="header"
                        textSize={props.locale == 'en' ? '29pt' : '21pt'}
                        mobileTextSize={props.locale == 'en' ? '27pt' : '24pt'}
                        margin="1% 0 0"
                        padding="0"
                        lineHeight={props.locale == 'en' ? '30pt' : '24pt'}
                        upperCase={true}
                        bolder={true}
                        textColor="#006838">
                        <FormattedMessage id='landing.2_2'>
                          {(txt) => (
                            <span dangerouslySetInnerHTML={{__html:txt}} />
                          )}
                        </FormattedMessage>
                      </Text>
                      <Text

                        textSize="12pt"
                        margin="5px 0 0"
                        padding="0"
                        lineHeight="16pt"
                        upperCase={true}
                        textColor="#006838">
                        <FormattedMessage id='landing.or_fantastik'>
                          {(txt) => (
                            <span dangerouslySetInnerHTML={{__html:txt}} />
                          )}
                        </FormattedMessage>
                      </Text>
                      <Text
                        margin="4% 0 0"
                        textColor="#51586E"
                        textSize="11pt"
                        lineHeight="18pt">
                        <FormattedMessage id='landing.2_3'>
                          {(txt) => (
                            <span dangerouslySetInnerHTML={{__html:txt}} />
                          )}
                        </FormattedMessage>
                      </Text>
                    </SizedContainer>
                  </Card>
                </SizedContainer>
                <SizedContainer
                  width="48%"
                  margin="1%">
                  <Card locale={props.locale}>
                    <RoundNumber>3</RoundNumber>
                      <SizedContainer
                        width="50%"
                        padding="4% 2% 2%"
                        margin="0 0 0">
                        <Imgix src={Product3} width={400} loading="lazy" alt="Product" />
                      </SizedContainer>
                      <SizedContainer
                        width="50%">
                        <Text

                          textSize="12pt"
                          margin="6% 0 0"
                          padding="0"
                          lineHeight="16pt"
                          upperCase={true}
                          textColor="#9AC623">
                          <FormattedMessage id='landing.3_1'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html:txt}} />
                            )}
                          </FormattedMessage>
                        </Text>
                        <Text
                          textType="header"
                          textSize={props.locale == 'en' ? '29pt' : '21pt'}
                          mobileTextSize={props.locale == 'en' ? '27pt' : '24pt'}
                          margin="1% 0 0"
                          padding="0"
                          lineHeight={props.locale == 'en' ? '30pt' : '24pt'}
                          upperCase={true}
                          bolder={true}
                          textColor="#006838">
                          <FormattedMessage id='landing.3_2'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html:txt}} />
                            )}
                          </FormattedMessage>
                        </Text>
                        <Text

                          textSize="12pt"
                          margin="5px 0 0"
                          padding="0"
                          lineHeight="16pt"
                          upperCase={true}
                          textColor="#006838">
                          <FormattedMessage id='landing.or_fantastik'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html:txt}} />
                            )}
                          </FormattedMessage>
                        </Text>
                        <Text
                          margin="4% 0 0"
                          textColor="#51586E"
                          textSize="11pt"
                          lineHeight="18pt">
                          <FormattedMessage id='landing.3_3'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html:txt}} />
                            )}
                          </FormattedMessage>
                        </Text>
                      </SizedContainer>
                  </Card>
                </SizedContainer>
                <SizedContainer
                  width="48%"
                  margin="1%">
                  <Card locale={props.locale}>
                    <RoundNumber>4</RoundNumber>
                      <SizedContainer
                        width="50%"
                        padding="4% 2% 2%"
                        margin="0 0 0">
                        <Imgix src={Product4} width={400} loading="lazy" alt="Product" />
                      </SizedContainer>
                      <SizedContainer
                        width="50%">
                        <Text

                          textSize="12pt"
                          margin="6% 0 0"
                          padding="0"
                          lineHeight="16pt"
                          upperCase={true}
                          textColor="#9AC623">
                          <FormattedMessage id='landing.4_1'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html:txt}} />
                            )}
                          </FormattedMessage>
                        </Text>
                        <Text
                          textType="header"
                          textSize={props.locale == 'en' ? '29pt' : '21pt'}
                          mobileTextSize={props.locale == 'en' ? '27pt' : '24pt'}
                          margin="1% 0 0"
                          padding="0"
                          lineHeight={props.locale == 'en' ? '30pt' : '24pt'}
                          upperCase={true}
                          bolder={true}
                          textColor="#006838">
                          <FormattedMessage id='landing.4_2'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html:txt}} />
                            )}
                          </FormattedMessage>
                        </Text>
                        <Text

                          textSize="12pt"
                          margin="5px 0 0"
                          padding="0"
                          lineHeight="16pt"
                          upperCase={true}
                          textColor="#006838">
                          <FormattedMessage id='landing.or_fantastik'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html:txt}} />
                            )}
                          </FormattedMessage>
                        </Text>
                        <Text
                          margin="4% 0 0"
                          textColor="#51586E"
                          textSize="11pt"
                          lineHeight="18pt">
                          <FormattedMessage id='landing.4_3'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html:txt}} />
                            )}
                          </FormattedMessage>
                        </Text>
                      </SizedContainer>
                  </Card>
                </SizedContainer>
                <SizedContainer
                  width="48%"
                  margin="1%">
                  <Card locale={props.locale}>
                    <RoundNumber>5</RoundNumber>
                      <SizedContainer
                        width="50%"
                        padding="4% 2% 2%"
                        margin="0 0 0">
                        <Imgix src={Product5} width={400} loading="lazy" alt="Product" />
                      </SizedContainer>
                      <SizedContainer
                        width="50%">
                        <Text

                          textSize="12pt"
                          margin="6% 0 0"
                          padding="0"
                          lineHeight="16pt"
                          upperCase={true}
                          textColor="#9AC623">
                          <FormattedMessage id='landing.5_1'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html:txt}} />
                            )}
                          </FormattedMessage>
                        </Text>
                        <Text
                          textType="header"
                          textSize={props.locale == 'en' ? '29pt' : '21pt'}
                          mobileTextSize={props.locale == 'en' ? '27pt' : '24pt'}
                          margin="1% 0 0"
                          padding="0"
                          lineHeight={props.locale == 'en' ? '30pt' : '24pt'}
                          upperCase={true}
                          bolder={true}
                          textColor="#006838">
                          <FormattedMessage id='landing.5_2'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html:txt}} />
                            )}
                          </FormattedMessage>
                        </Text>
                        <Text
                          margin="4% 0 0"
                          textColor="#51586E"
                          textSize="11pt"
                          lineHeight="18pt">
                          <FormattedMessage id='landing.5_3'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html:txt}} />
                            )}
                          </FormattedMessage>
                        </Text>
                      </SizedContainer>
                  </Card>
                </SizedContainer>
                <SizedContainer
                  width="48%"
                  margin="1%">
                  <Card locale={props.locale}>
                    <RoundNumber>6</RoundNumber>
                      <SizedContainer
                        width="50%"
                        padding="4% 2% 2%"
                        margin="0 0 0">
                        <Imgix src={Product6} width={400} loading="lazy" alt="Product" />
                      </SizedContainer>
                      <SizedContainer
                        width="50%">
                        <Text

                          textSize="12pt"
                          margin="6% 0 0"
                          padding="0"
                          lineHeight="16pt"
                          upperCase={true}
                          textColor="#9AC623">
                          <FormattedMessage id='landing.6_1'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html:txt}} />
                            )}
                          </FormattedMessage>
                        </Text>
                        <Text
                          textType="header"
                          textSize={props.locale == 'en' ? '29pt' : '21pt'}
                          mobileTextSize={props.locale == 'en' ? '27pt' : '24pt'}
                          margin="1% 0 0"
                          padding="0"
                          lineHeight={props.locale == 'en' ? '30pt' : '24pt'}
                          upperCase={true}
                          bolder={true}
                          textColor="#006838">
                          <FormattedMessage id='landing.6_2'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html:txt}} />
                            )}
                          </FormattedMessage>
                        </Text>
                        <Text
                          margin="4% 0 0"
                          textColor="#51586E"
                          textSize="11pt"
                          lineHeight="18pt">
                          <FormattedMessage id='landing.6_3'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html:txt}} />
                            )}
                          </FormattedMessage>
                        </Text>
                      </SizedContainer>
                  </Card>
                </SizedContainer>
                <SizedContainer
                  width="40%"
                  margin="20px 30%">
                  <ResponsiveText
                    center={true}
                    textColor="#405d0b"
                    textSize="16pt"
                    lineHeight="22pt">
                    <FormattedMessage id='landing.look' />
                  </ResponsiveText>
                </SizedContainer>
              </FlexContainer>
            </Landing>
          </LandingWrapper>
          <ProductLocatorBlock
            title={null}
            upc={[
                  "063435702208",
                  "063435702161",
                  "063435702192",
                  "063435702178",
                  "063435702208",
                  "063435720356",
                  "063435720219",
                  "063435720226",
                  "063435720523"
                ]}
            modification={null}
            key={'productlocator'}
          />
      <ContestFooter
          extraLines
          landing={true}
          locale={props.locale}  />
    </>
  )
}

export default LandingPage
